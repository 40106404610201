/* src/components/Footer.css */

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 200px; /* Increased height for the footer */
  box-sizing: border-box;
  color: white;
  position: relative; /* Ensure positioned elements are contained */
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px; /* Constrain content width */
  padding: 0 20px; /* Add horizontal padding for content */
}

.footer-logo {
  height: 100px; /* Set a larger height for the logo */
  width: auto; /* Maintain aspect ratio */
  object-fit: contain;
  display: block;
  margin-left: 20px; /* Adjust logo position to be inside container */
}

.footer-center {
  display: flex;
  flex-direction: column; /* Stack button and text vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center content vertically */
  position: absolute; /* Position center content absolutely */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Offset positioning */
}

.footer-right-image {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%; /* Ensure it fits the full height of the footer */
  width: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the container area */
  display: block;
  margin: 0; /* Remove all margins to fit the container */
  padding: 0; /* Ensure no padding */
}

.footer-text {
  text-align: center; /* Center the text horizontally */
  font-size: 14px; /* Adjust font size as needed */
  color: white; /* Ensure text is white */
  margin-top: 10px; /* Add margin above text for spacing */
}

.footer-button {
  background-color: transparent; /* Transparent background */
  color: white;
  border: 2px solid white; /* White border */
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 10px; /* Add margin below button for spacing */
}

.footer-button:hover {
  background-color: white; /* White background on hover */
  color: #e37f7f; /* Coral text color on hover */
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    height: 250px; /* Increase height for mobile view */
    padding: 20px 0; /* Add vertical padding */
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    width: 100%;
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .footer-logo {
    display: none;
  }

  .footer-right-image {
    display: none; /* Hide the right image on mobile */
  }

  .footer-center {
    margin-top: 10px; /* Add spacing above the center section */
    align-items: center; /* Center align content */
    text-align: center; /* Center align content */
  }

  .footer-text {
    font-size: 12px; /* Smaller font for mobile */
    padding: 5px 0; /* Adjust padding */
    text-align: center; /* Center text for mobile */
    width: 100%; /* Ensure text takes full width */
  }
}
