.hero {
  position: relative;
  text-align: center;
  color: white;
  height: 500px; /* Set a fixed height for the hero section */
  overflow: hidden; /* Ensures content stays within the hero section */
}

.hero-image {
  width: 100%;
  height: 100%; /* Make the image fill the hero section */
  object-fit: cover; /* Ensure the image covers the entire area */
  display: block;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 10px)); /* Adjust top margin for content */
  max-width: 90%; /* Ensures content doesn't overflow */
  box-sizing: border-box;
  padding: 15px;
}

.hero-title {
  font-size: 2.5rem;
  margin: 0;
}

.animated-text {
  animation: colorFlash 5s infinite;
}

@keyframes colorFlash {
  0% {
    color: #ff69b4;
  }
  25% {
    color: #ff6347;
  }
  50% {
    color: #1e90ff;
  }
  75% {
    color: #ae32cd;
  }
  100% {
    color: #ff69b4;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .hero {
    height: 600px; /* Set a different fixed height for mobile if needed */
  }

  .hero-content {
    top: 50%; /* Adjust top position to add more space above the text */
    transform: translate(-50%, -50%); /* Adjust for centering with new top value */
    font-size: 2vh; /* Adjusts font size based on viewport height */
    padding: 5px;
  }

  .hero-title {
    font-size: 4vh; /* Adjusts title size based on viewport height */
  }
}
