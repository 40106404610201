/* src/components/Projects.css */

.project-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.project-content {
  text-align: center; /* Center align text */
  padding: 0px;
}

.project-title {
  text-align: center;
  margin: 0px 0;
}

.project-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between cards */
  padding: 20px;
}

.project-card {
  flex: 1;
  min-width: 300px; /* Ensure minimum width for better appearance */
  margin-right: 20px; /* Add margin to the right */
  box-sizing: border-box;
  text-align: center; /* Center align text inside cards */
}

.separator {
  width: 100%;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .project-section {
    padding: 10px;
  }

  .project-content {
    padding: 10px;
    text-align: center; /* Center align text on mobile */
  }

  .project-title {
    font-size: 1.5rem;
  }

  .project-cards {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;
  }

  .project-card {
    width: 100%;
    max-width: 400px;
    margin-right: 0; /* Remove right margin on mobile */
    text-align: center; /* Center align text inside cards */
  }
}

.featured-canvas {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: block;
  box-sizing: border-box;
}
