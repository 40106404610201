/* src/components/PersonCard.css */

.person-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    text-align: center;
    width: 300px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow: hidden; /* Ensure no overflow when expanded */
  }
  
  .person-card.expanded {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  }
  
  .person-image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .person-name {
    font-size: 1.25rem;
    margin: 0;
  }
  
  .person-role {
    color: #555;
    margin: 5px 0;
  }
  
  .person-skills {
    list-style-type: none;
    padding: 0;
    margin: 10px 0;
    text-align: left;
  }
  
  .person-skills li {
    margin-bottom: 5px;
  }
  
  .person-skills strong {
    font-weight: bold;
  }
  
  .person-skills ul {
    margin-left: 20px; /* Indent sub-skills */
  }
  
  button {
    background-color: #e37f7f;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
  }
  
  button:hover {
    background-color: #ed5a5a;
  }
  