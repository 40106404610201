/* src/components/Separator.css */

.separator {
    width: 100%; /* Full width of the container */
    margin: 10px 0; /* Margin for spacing between sections */
    overflow: hidden; /* Ensure no overflow if image aspect ratio doesn't match */
  }
  
  .separator-image {
    width: 100%; /* Stretch the image to fill the container */
    height: auto; /* Maintain aspect ratio */
    max-height: 20px; /* Limit the height of the image */
    display: block; /* Remove any inline spacing issues */
    object-fit: cover; /* Cover the entire width and height, crop if necessary */
  }
  