/* src/components/ProjectCard.css */

.project-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin: 20px 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
    max-width: 300px; /* Adjust as needed */
  }
  
  .project-card.expanded {
    max-width: 350px; /* Slightly larger when expanded */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .project-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .project-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .project-description {
    font-size: 1rem;
    color: #555;
  }
  
  .project-extra {
    font-size: 0.9rem;
    color: #777;
    margin-top: 10px;
  }
  