body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* src/components/Hero.css */

.hero {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust the height as needed */
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the area without distortion */
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white; /* Adjust the text color for readability */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Add a semi-transparent background */
  padding: 20px;
  border-radius: 8px;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin: 0;
}

.hero-content p {
  font-size: 1.2rem;
  margin-top: 10px;
}
