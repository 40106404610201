/* src/pages/Contact.css */

.contact-page {
    padding: 20px;
  }
  
  .contact-header-section {
    padding: 60px 20px;
    text-align: center;
    color: white;
    margin-bottom: 40px;
    border-radius: 8px;
    margin-top: 40px; /* Add top margin to the contact section */
  }
  
  .contact-header-title {
    font-size: 3rem;
    margin: 0;
  }
  
  .contact-personnel-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  
  .person-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .person-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .person-name {
    font-size: 1.5rem;
    margin: 10px 0;
    font-weight: bold;
  }
  
  .person-role {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  .person-contact {
    font-size: 1rem;
    color: #333;
  }
  
  .person-contact a {
    color: #007bff;
    text-decoration: none;
  }
  
  .person-contact a:hover {
    text-decoration: underline;
  }
  