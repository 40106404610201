
.partners-section {
    text-align: center;
    margin-top: 40px;
  }
  
  .partners-header {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .partners-list {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: center; /* Center the partners */
    gap: 20px; /* Space between partner blocks */
  }