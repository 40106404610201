/* src/components/Partner.css */

.partner {
    text-align: center;
    margin: 20px;
    width: 150px; /* Width for each partner block */
  }
  
  .partner-image {
    width: 100px; /* Size of the image container */
    height: 100px;
    margin: 0 auto; /* Center the image */
    border-radius: 8px; /* Optional: rounded corners */
    overflow: hidden;
    border: 2px solid #ddd; /* Optional: add a border for aesthetics */
  }
  
  .partner-image img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Fit the image within the container */
  }
  
  .partner-name {
    font-size: 0.9rem;
    margin-top: 10px;
    color: #333;
    font-weight: bold; /* Make the text bold */
  }
  

.partners-section {
    text-align: center;
    margin-top: 40px;
    padding: 40px 20px; /* Add padding inside the partners section */
    background-color: #f9f9f9; /* Optional: add a background color for distinction */
    border-radius: 8px; /* Optional: add rounded corners for aesthetics */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow */
  }

  
.partners-header {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .partners-list {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping to the next row */
    justify-content: center; /* Center the partners */
    gap: 20px; /* Space between partner blocks */
  }
  