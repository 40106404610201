/* src/components/Header.css */

.header {
  position: fixed; /* Keep the header fixed to the top */
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  height: 70px;
  z-index: 1000;
  box-sizing: border-box;
}

.navigation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu {
  list-style-type: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.menu > li {
  position: relative;
}

.menu a,
.menu span {
  text-decoration: none;
  cursor: pointer;
  padding: 10px;
  color: #333;
  display: flex;
  align-items: center;
  transition: color 0.3s ease, border-color 0.3s ease;
  border-bottom: 2px solid transparent;
}

.menu a:hover,
.menu span:hover {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.menu a.active {
  font-weight: bold;
  border-bottom: 2px solid transparent;
}

.logo {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-right: 20px;
  box-sizing: border-box;
}

.logo-image {
  height: 50px;
  width: auto;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  list-style-type: none;
  padding: 10px;
  margin: 0;
  z-index: 1001;
}

.menu > li:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  margin: 0;
}

.dropdown-menu a {
  display: block;
  padding: 8px 12px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
}

.dropdown-menu a:hover {
  background-color: #f0f0f0;
}

.hamburger {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 20px;
  outline: none; /* Remove focus outline */
}

.hamburger:focus,
.hamburger:active {
  outline: none; /* Remove outline on focus and active states */
  background: none; /* Ensure no background color is applied */
}

@media (max-width: 768px) {
  .header {
    justify-content: space-between; /* Spread header content */
    padding: 10px; /* Consistent padding */
  }

  .navigation {
    justify-content: flex-start;
    flex-grow: 1; /* Allow navigation to take available space */
  }

  .menu {
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ddd;
    display: none;
    gap: 0;
    padding: 10px 0;
  }

  .menu.open {
    display: flex;
  }

  .menu > li {
    padding: 10px;
  }

  .menu a,
  .menu span {
    padding: 8px;
    font-size: 16px;
    border-bottom: none;
  }

  .hamburger {
    display: block;
    color: #e37f7f; /* Coral color for the hamburger icon */
    margin-left: auto; /* Align the hamburger to the right */
  }
}
