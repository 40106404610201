/* src/pages/About.css */

.about-page {
  padding: 20px;
}

.intro-section {
  padding: 40px;
  margin-bottom: 40px;
  border-radius: 8px;
  color: white;
  text-align: center;
}

.intro-content {
  max-width: 800px;
  margin: 0 auto;
}

.intro-image {
  width: 150px;
  height: auto;
  object-fit: contain;
  margin-bottom: 20px;
  margin-top: 20px;
}

.intro-header {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.intro-text {
  font-size: 1rem;
  line-height: 1.5;
}

.about-header {
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the items horizontally */
  gap: 20px;
}

.person-card {
  flex: 1 1 calc(33.333% - 40px); /* Allow up to three cards per row with margin adjustment */
  max-width: 320px; /* Max width to prevent excessive growth */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 20px; /* Ensure spacing between rows */
}

.person-card.expanded {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.05); /* Slightly grow the card on expansion */
}

.person-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
}

.person-name {
  font-size: 1.25rem;
  margin: 0;
}

.person-role {
  color: #555;
  margin: 5px 0;
}

.person-skills {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  text-align: left;
}

.person-skills li {
  margin-bottom: 5px;
}

.person-skills strong {
  font-weight: bold;
}

.person-skills ul {
  margin-left: 20px; /* Indent sub-skills */
}

@media (max-width: 768px) {
  .person-card {
    flex: 1 1 100%; /* Full width on small screens */
  }
}
